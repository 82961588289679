<template>
    <div class="container">
        <div class="page-title">
            <el-row>
                <el-col :span="18">
                    <h3>操作日志</h3>
                </el-col>
                <el-col :span="6" style="text-align: right;">

                </el-col>
            </el-row>
        </div>
        <div class="wrap">
            <div class="query-box">
                <el-form :inline="true" :model="queryParams" @submit.native.prevent>
                    <el-form-item label="日期" style="margin-bottom: 0">
                        <el-date-picker
                                v-model="queryParams.date"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期" style="width:150px">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="关键词" style="margin-bottom: 0">
                        <el-input placeholder="可输入用户名、ip、操作关键字" v-model="queryParams.keys" style="width: 280px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 0">
                        <el-button type="primary" native-type="submit" @click="searchData"><i class="el-icon-search"></i> 查询</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <el-table :data="tableData" stripe style="width: 100%" ref="multipleTable"  v-loading="loading">
                <el-table-column prop="id" label="ID" width="60" sortable>
                </el-table-column>
                <el-table-column prop="created_at" label="时间" width="180" sortable>
                </el-table-column>
                <el-table-column prop="username" label="操作者" width="180">
                </el-table-column>
                <el-table-column prop="note" label="操作内容">
                </el-table-column>
              <el-table-column prop="reson" label="操作原因">
              </el-table-column>
                <el-table-column prop="ip" label="IP" width="180">
                </el-table-column>
                <el-table-column prop="useragent" label="UserAgent">
                </el-table-column>
            </el-table>
            <div class="pagenation">
                <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        layout="total, prev, pager, next"
                        :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Log",
        data () {
            return {
                loading: true,
                queryParams: {
                    date: '',
                    keys: '',
                },
                tableData: [],
                currentPage: 1,
                pageSize: 15,
                total: 0
            }
        },
        created () {
            this.getListData()
        },
        methods: {
            getListData () {
                this.queryParams.page = this.currentPage;
                this.$get(this.$api.GetLogList, this.queryParams)
                    .then((res) => {
                        this.loading = false;
                        this.tableData = res.data.list;
                        this.total = res.data.total;
                    });
            },
            searchData() {
                this.loading = true;
                this.tableData = [];
                this.currentPage = 1;
                this.getListData();
            },
            handleCurrentChange (val) {
                this.currentPage = val
                this.getListData()
            },

        }
    }
</script>

<style scoped>
    .query-box {
        background: #f6f6f6;
        padding: 10px;
        margin: 10px 0;
    }
    .pagenation{
        margin: 20px auto;
        text-align: center;
    }
</style>
